import { store } from './redux/store.js';
import routes from './routes';
import { Router } from './utils/router/Router.js';
import getDefaultParam from './utils/getDefaultParam';

const router = new Router(store);

const hooks = {
  before: doneCallback => {
    const done = (goToNext = true) => {
      doneCallback(goToNext);
    };

    const event = new CustomEvent('beforenav', { cancelable: true, detail: { done } });

    if (window.dispatchEvent(event)) {
      done();
    }
  }
};

router
  .params(getDefaultParam)
  .hooks(hooks)
  .on(routes);

export default router;