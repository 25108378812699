/**
 * @param {Object.<string, string>} query The parts of the query to build.
 * @return {string} The constructed string (including the beginning ?) or an empty string if query was empty.
 */
const queryToString = (query = undefined) => {
  if (!query) return '';

  const joined = Object.entries(query ?? {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${joined.length ? '?' : ''}${joined}`;
};

export default queryToString;