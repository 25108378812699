import {
  UPDATE_PAGE,
  UPDATE_CONFIG,
  UPDATE_SETTINGS,
  UPDATE_ME,
  UPDATE_PATIENT,
  SHOW_OVERLAY, VERIFY_DISABLED, AUTHORIZE_PAGE
} from './types.js';

const INITIAL_STATE = {};

const showOverlay = (state, { show }) =>
  ({ ...state, showOverlay: show });

const updatePage = (state, { page, params, query, requiresAuth }) =>
  ({ ...state, page, params, query, authReady: !requiresAuth, authGranted: requiresAuth ? undefined : true });

// If page doesn't match the current page, it means they've navigated to a new page before we authed,
//  so throw away this auth.
const authorizedPage = (state, { page, granted }) =>
  page === state.page
    ? ({ ...state, authReady: true, authGranted: Boolean(granted) })
    : state;

const updateConfig = (state, { config }) =>
  ({ ...state, config });

const updateSettings = (state, { settings }) =>
  ({ ...state, settings, settingsLoaded: true });

const updateMe = (state, { me }) =>
  ({ ...state, me });

const updatePatient = (state, { patient }) =>
  ({ ...state, patient });

const verifyDisabled = (state, { disabled }) =>
  ({ ...state, verifyDisabled: disabled });

const reducers = {
  [UPDATE_PAGE]: updatePage,
  [AUTHORIZE_PAGE]: authorizedPage,
  [UPDATE_CONFIG]: updateConfig,
  [UPDATE_SETTINGS]: updateSettings,
  [UPDATE_ME]: updateMe,
  [UPDATE_PATIENT]: updatePatient,
  [SHOW_OVERLAY]: showOverlay,
  [VERIFY_DISABLED]: verifyDisabled
};

const app = (state = INITIAL_STATE, action) =>
  (reducers[action.type] || (state => state))(state, action);

export default app;
