/* global process */
import { html } from 'lit';
import { devRoutes } from '../dev/routes.js';
import {
  ACCESS_WEEKLY_PERMISSION,
  BATCH_PERMISSION,
  RESEARCH_PERMISSION
} from './components/views/weekly-plan-check-view/common.js';

export const Routes = {
  Root: 'root',
  WeeklyCheckLanding: 'weekly-check-landing',
  WeeklyCheckOrganization: 'weekly-check-organization',
  WeeklyCheckPatient: 'weekly-check-patient',
  NotFound404: '404-not-found',
  OrganizationSettingsMain: 'organization-settings-main',
  OrganizationSettingsSub: 'organization-settings-sub',
  Document: 'document',
  Image: 'image',
  DoseAccumulated: 'dose-accmulated',
  DoseDelivered: 'dose-delivered',
  Landing: 'landing',
  Login: 'login',
  Logout: 'logout',
  AdminUsers: 'auth-users',
  AdminOrganizations: 'auth-organizations',
  AdminPermissions: 'auth-permissions',
  AdminRoot: 'landing-root',
  AdminLanding: 'landing-admin',
  ResetPassword: 'reset-password',
  ForgotPassword: 'forgot-password',
  BlankImage: 'images-not-loaded',
  Batch: 'batch',
  Image2: 'image2',
  SupportImageViewer: 'support-image-viewer',
  SupportImageViewerIndex: 'support-image-viewer-index',
  ResearchLanding: 'research-landing',
  ResearchPatient: 'research-patient'
};

/** @type {Object.<string, RouteConfig>} */
const routes = {
  [Routes.Root]: {
    route: '/',
    forward: Routes.WeeklyCheckLanding
  },
  [Routes.WeeklyCheckLanding]: {
    route: '/weekly-check',
    permissions: [ACCESS_WEEKLY_PERMISSION],
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  'organization-without-patient': {
    route: '/weekly-check/:organization',
    permissions: [ACCESS_WEEKLY_PERMISSION],
    forward: Routes.WeeklyCheckOrganization
  },
  [Routes.WeeklyCheckPatient]: {
    route: '/weekly-check/:organization/patient/:patientSer',
    permissions: [ACCESS_WEEKLY_PERMISSION],
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  [Routes.WeeklyCheckOrganization]: {
    route: '/weekly-check/:organization/patient',
    permissions: [ACCESS_WEEKLY_PERMISSION],
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  [Routes.OrganizationSettingsMain]: {
    route: '/weekly-check/:organization/settings',
    view: () => import('./components/views/settings-view/index.js'),
    title: 'WeeklyCheck Settings',
    render: () => html`<settings-view></settings-view>`
  },
  [Routes.OrganizationSettingsSub]: {
    route: '/weekly-check/:organization/settings/:page',
    view: () => import('./components/views/settings-view/index.js'),
    title: 'WeeklyCheck Settings',
    render: () => html`<settings-view></settings-view>`
  },
  [Routes.Document]: {
    route: '/weekly-check/:organization/document/:documentType/:documentId',
    view: () => import('./components/views/document-view/index.js'),
    title: 'WeeklyCheck - Document',
    render: () => html`<document-view></document-view>`
  },
  [Routes.Image]: {
    route: '/weekly-check/:organization/image/:ser/:planSer/:fraction/:index',
    view: () => import('./components/views/skia-view/index.js'),
    title: 'WeeklyCheck - Skia',
    render: () => html`<skia-view mode="image"></skia-view>`
  },
  [Routes.DoseAccumulated]: {
    route: '/weekly-check/:organization/dose/accumulated/:ser/:planSer/:planSumSer/:index',
    view: () => import('./components/views/skia-view/index.js'),
    title: 'WeeklyCheck - Skia',
    render: () => html`<skia-view mode="dose" type="accumulated"></skia-view>`
  },
  [Routes.DoseDelivered]: {
    route: '/weekly-check/:organization/dose/delivered/:ser/:planSer/:fraction/:index',
    view: () => import('./components/views/skia-view/index.js'),
    title: 'WeeklyCheck - Skia',
    render: () => html`<skia-view mode="dose" type="delivered"></skia-view>`
  },
  [Routes.NotFound404]: {
    notFound: true,
    view: () => import('./components/views/error-404-view.js'),
    title: '404 Not Found',
    render: () => html`<error-404-view></error-404-view>`
  },
  [Routes.Login]: {
    route: '/auth/login',
    view: () => import('./components/views/auth/login-view'),
    title: 'Login',
    render: () => html`<login-view></login-view>`
  },
  [Routes.Logout]: {
    route: '/auth/logout',
    forward: '/login'
  },
  [Routes.AdminRoot]: {
    route: '/auth',
    view: () => import('./components/views/auth/users-view'),
    title: 'Admin - Users',
    render: () => html`<users-view></users-view>`
  },
  [Routes.AdminLanding]: {
    route: '/auth/:organization',
    forward: Routes.AdminUsers
  },
  [Routes.AdminUsers]: {
    route: '/auth/:organization/users',
    view: () => import('./components/views/auth/users-view'),
    title: 'Admin - Users',
    render: () => html`<users-view></users-view>`
  },
  [Routes.AdminOrganizations]: {
    route: '/auth/:organization/organizations',
    view: () => import('./components/views/auth/organizations-view'),
    title: 'Admin - Organizations',
    render: () => html`<organizations-view></organizations-view>`
  },
  [Routes.AdminPermissions]: {
    route: '/auth/:organization/permissions',
    view: () => import('./components/views/auth/permissions-view'),
    title: 'Admin - Permissions',
    render: () => html`<permissions-view></permissions-view>`
  },
  [Routes.ResetPassword]: {
    route: '/auth/password/reset',
    view: () => import('./components/views/auth/password-reset-view'),
    title: 'Password Reset',
    render: () => html`<password-reset-view></password-reset-view>`
  },
  [Routes.ForgotPassword]: {
    route: '/auth/password/forgot',
    view: () => import('./components/views/auth/password-forgot-view'),
    title: 'Forgot Password',
    render: () => html`<password-forgot-view></password-forgot-view>`
  },
  [Routes.BlankImage]: {
    route: '/weekly-check/:organization/image/:ser/blank',
    view: () => import('./components/views/blank-image-view.js'),
    title: 'Images Not Loaded',
    render: () => html`<blank-image-view></blank-image-view>`
  },
  [Routes.Batch]: {
    route: '/weekly-check/:organization/batch',
    permissions: [BATCH_PERMISSION],
    view: () => import('./components/views/batch-view'),
    title: 'Batch Approval',
    render: () => html`<batch-view></batch-view>`
  },
  [Routes.SupportImageViewer]: {
    route: '/support/image',
    view: () => import('./support/support-skia-viewer.js'),
    title: 'Skia Image Viewer',
    render: () => html`<support-skia-viewer></support-skia-viewer>`
  },
  [Routes.SupportImageViewerIndex]: {
    route: '/support/image/:index',
    view: () => import('./support/support-skia-viewer.js'),
    title: 'Skia Image Viewer',
    render: () => html`<support-skia-viewer></support-skia-viewer>`
  },
  [Routes.ResearchLanding]: {
    route: '/weekly-check/:organization/research',
    permissions: [RESEARCH_PERMISSION],
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'Research Review',
    render: () => html`<weekly-plan-check-view .researchMode=${true}></weekly-plan-check-view>`
  },
  [Routes.ResearchPatient]: {
    route: '/weekly-check/:organization/research/patient/:patientSer',
    permissions: [RESEARCH_PERMISSION],
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view .researchMode=${true}></weekly-plan-check-view>`
  }
};

if (process.env.NODE_ENV !== 'production') {
  Object.assign(routes, devRoutes);
}

export default routes;
