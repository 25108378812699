import radHttp from '../rad-http/index.js';
import queryToString from '../rad-http/utils/queryToString.js';
import { authorize, navigate } from '../../redux/app/actions';
import { getQueryParams } from '../query/index.js';
import flattenPermissions from '../auth/flattenPermissions.js';
import getStore from '../redux/store/getStore.js';

export class Router {
  /**
   * @type {Promise<RadHttpResponse<UserInfo>>}
   * A Promise that eventually resolves to /me or produces an error.
   */
  me;

  /** @type {(function(string):string)[]} */
  paramGetters = [];

  /** @type {function((boolean))} */
  beforeCallback;

  /** @type {RouteUsesFunction} */
  notFoundCallback;

  /** @type {Route} */
  notFoundRoute;

  /** @type {Route[]} */
  routes = [];

  constructor() {
    this.loadMe();

    window.addEventListener('popstate', () => {
      this.resolve();
    });
  }

  loadMe() {
    this.me = radHttp.get('./auth/api/me');

    return this;
  }

  navigate(path) {
    if (!this.beforeCallback) {
      this.#doNavigate(path);
      return;
    }

    this.beforeCallback((resolve = true) => {
      resolve && this.#doNavigate(path);
    });
  }

  generate(routeName, params = {}, query = {}, hash = '') {
    const route = this.routes.find(({ pageName }) => pageName === routeName);

    if (!route) return undefined;

    const paramsToReplace = route.route.match(/(?<=:)([^/]+)/g) ?? [];

    const url = paramsToReplace
      .reduce((str, key) => str.replace(
        new RegExp(`:${key}(/?)`, 'g'),
        `${params[key] ?? this.paramGetters.find(getter => getter(key))?.(key)}$1`
      ), route.route);

    const queryParamsString = queryToString(query);

    return `${url}${queryParamsString}${hash ? `#${hash}` : ''}`;
  }

  /**
   * Resolves the given (or current) path
   * @param {string} path
   * @returns {{ route:{ pageName:string, path:string, resolved:string }, params:Object.<string, string> }}
   */
  resolve(path = location.pathname) {
    const route = this.routes.find(({ pattern }) => pattern.test(path));

    if (!route) {
      this.notFoundCallback?.();

      return {
        route: { pageName: this.notFoundRoute?.pageName, path: undefined },
        params: {},
        resolved: path
      };
    }

    const { pattern, pageName, uses, permissions } = route;
    const { groups: params } = path.match(pattern);
    const query = getQueryParams();
    const requiresAuth = Boolean(permissions?.length);

    uses(params, query, requiresAuth);

    if (requiresAuth) {
      // noinspection JSIgnoredPromiseFromCall
      this.#authorize(pageName, permissions, params?.organization);
    }

    return {
      route: { pageName: route.pageName, path: route.route },
      params: {},
      resolved: path
    };
  }

  /**
   * Registers routes to the router.
   * @param {Object.<string, RouteConfig>} routeConfig An object of route configs. The key is their route name.
   * @returns {Router}
   */
  on(routeConfig) {
    this.routes = this.#buildRoutes(routeConfig);

    const [notFoundPageName, notFoundRoute] = Object.entries(routeConfig)
      .find(([_, { notFound }]) => notFound) ?? [];

    if (notFoundRoute) {
      this.notFound(notFoundRoute, notFoundPageName);
    }

    return this;
  }

  notFound(notFoundRoute, pageName = 'not-found') {
    this.notFoundRoute = notFoundRoute;
    this.notFoundCallback = this.#changePageUses(pageName, notFoundRoute.view);
    return this;
  }

  hooks({ before }) {
    this.beforeCallback = before;
    return this;
  }

  params(...paramGetters) {
    this.paramGetters = paramGetters;
    return this;
  }

  #doNavigate(path) {
    history.pushState('', '', path);
    this.resolve(`/${path}`.replace(/^\/+/, '/'));
    document.dispatchEvent(new CustomEvent('navigate'));
  }

  async #authorize(pageName, permissions, organization) {
    const { response: { status }, data } = await this.me;

    if (status !== 200) {
      getStore().dispatch(authorize(pageName, false));
      return;
    }

    const userPermissions = flattenPermissions(data, organization);

    getStore().dispatch(authorize(pageName, permissions.every(p => userPermissions.includes(p))));
  }

  /**
   * Converts RouteConfig objects into intermediate Route objects
   * @param {Object.<string, RouteConfig>} routeConfigs
   * @returns {Route[]}
   */
  #buildRoutes(routeConfigs) {
    return Object.entries(routeConfigs)
      .filter(([, { notFound }]) => !notFound)
      .map(([pageName, { view, route, forward, permissions, notFound }]) => {
        return {
          pageName,
          route,
          permissions,
          notFound,
          uses: forward
            ? this.#forwardPageUses(pageName, forward)
            : this.#changePageUses(pageName, view),
          pattern: new RegExp(`^${route.replace(/\//, '\\/')
            .replace(/:([^/]+)!/g, '(?<$1>.+)')
            .replace(/:([^/]+)/g, '(?<$1>[^\\/]+)')}$`)
        }
      });
  }

  /** @return {RouteUsesFunction} */
  #changePageUses(pageName, loadFile = () => {}) {
    return (params = {}, query = '', requiresAuth = false) => {
      getStore().dispatch(navigate(pageName, loadFile, params, query, requiresAuth));
    };
  }

  /** @return {RouteUsesFunction} */
  #forwardPageUses(_, forward) {
    return (params = {}, query = '') => {
      this.navigate(`${this.generate(forward, params)}${queryToString(query)}`);
    };
  }
}

/**
 * @typedef RouteConfig
 * @property {string} route The path for the route
 * @property {string[]} permissions Permissions required for the route
 * @property {function():Promise} view Loads the view
 * @property {string} title The subtitle to add when on this page
 * @property {function():Promise.<import('lit').TemplateResult>} render Renders the route
 * @property {string} forward Indicates another route to forward to
 * @property {boolean} notFound Indicates if this is the route for not found
 */

/**
 * @typedef {function(params:Object=, query:string=, requiredAuth:boolean=)} RouteUsesFunction
 */

/**
 * @typedef Route
 * @property {string} pageName The name of the route
 * @property {string} route The route of the route
 * @property {RegExp} pattern The pattern to use when matching the route
 * @property {RouteUsesFunction} uses The method called when this route is resolved
 * @property {string[]} permissions The permissions needed for this route
 */