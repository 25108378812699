import safeSelect from '../object/safeSelect.js';
import getStore from './store/getStore.js';

const createSelector = (pathOrPaths, defaultValue = undefined) =>
  (state = getStore().getState()) =>
    (Array.isArray(pathOrPaths) ? pathOrPaths : [pathOrPaths])
      .map(path => safeSelect(state || getStore().getState(), path))
      .find(Boolean)
        || defaultValue;

export default createSelector;