import distinct from '../immutable/array/distinct.js';

/**
 * Flattens the permissions into an easy-to-search array
 * @param {Object} me The data from the /me endpoint
 * @param {string=} organizationSlug Optional, the organizationSlug to include permissions from.
 *  If omitted, permissions from all organizations are returned.
 * @return {string[]} An array of all permissions
 */
const flattenPermissions = (me, organizationSlug = undefined) =>
  distinct(
    (organizationSlug
      ? (me.organizations.find(({ slug }) => slug === organizationSlug)?.products ?? [])
      : me.organizations.flatMap(({ products }) => products)
    ).flatMap(({ roles }) => roles.flatMap(({ permissions }) => permissions.flatMap(({ id }) => id)))
  );

export default flattenPermissions;