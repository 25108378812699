﻿export const isInitialStatus = status => status.toLowerCase() === 'initial';
export const isWeeklyStatus = status => status.toLowerCase() === 'weekly';
export const isCompletedStatus = status => status.toLowerCase() === 'completed';
export const isEndOfTreatmentStatus = status => status.toLowerCase() === 'endOfTreatment'.toLowerCase();

export const PRODUCT_NAME = 'ChartCheck'; // should match the server
export const ACCESS_WEEKLY_PERMISSION = 'CHARTCHECK_CAN_ACCESS_WEEKLY_CHECK';
export const SETTINGS_PERMISSION = 'CHARTCHECK_CAN_EDIT_WEEKLY_CHECK_SETTINGS';
export const APPROVE_PERMISSION = 'CHARTCHECK_CAN_APPROVE_WEEKLY_CHECK';
export const RESEARCH_PERMISSION = 'CHARTCHECK_CAN_ACCESS_RESEARCH_MODE';
export const BATCH_PERMISSION = 'CHARTCHECK_CAN_BATCH_APPROVE';
export const BATCH_SETTINGS_PERMISSION = 'CHARTCHECK_CAN_EDIT_BATCH_SETTINGS';
export const ASSIGN_CHECK_TEMPLATE_PERMISSION = 'CHARTCHECK_CAN_APPROVE_WEEKLY_CHECK';

export const statuses = {
  initial: 'First Weekly Check',
  weekly: 'Weekly Chart Check',
  endOfTreatment: 'EoT Chart Check',
  completed: 'Completed',
  checkedToday: 'Checked Today'
};

export const states = {
  pass: 'Pass',
  fail: 'Fail',
  condition: 'Condition',
  override: 'Override'
};

export const statusFilters = [
  'initial',
  'weekly',
  'endOfTreatment',
  'completed',
  'checkedToday'
];

export const stateFilters = [
  'pass',
  'fail',
  'condition',
  'override'
];

export const FilterModes = {
  PATIENT: 'patient',
  DATE: 'date',
  IMPORT: 'import'
};

// Map to { [key]: { key, text, start, end } } for ease of use.
export const DateOptions = Object.entries({
  THIS_WEEK: { text: 'Last 7 Days', start: -7, end: 0 },
  LAST_TWO_WEEKS: { text: 'Last 14 Days', start: -14, end: 0 },
  THIS_MONTH: { text: 'Last 30 Days', start: -30, end: 0 },
  CUSTOM: { text: 'Custom Range', custom: true }
}).reduce((result, [key, value]) => ({ ...result, [key]: { key, ...value } }), {});