﻿import {
  UPDATE_CONFIG,
  UPDATE_ME,
  UPDATE_PAGE,
  UPDATE_SETTINGS,
  UPDATE_PATIENT,
  SHOW_OVERLAY,
  VERIFY_DISABLED,
  AUTHORIZE_PAGE
} from './types';

export const disableVerify = () => dispatch => {
  dispatch({ type: VERIFY_DISABLED, disabled: true });
};

export const enableVerify = () => dispatch => {
  dispatch({ type: VERIFY_DISABLED, disabled: false });
};

export const showOverlay = () => dispatch => {
  dispatch({ type: SHOW_OVERLAY, show: true });
};

export const hideOverlay = () => dispatch => {
  dispatch({ type: SHOW_OVERLAY, show: false });
};

export const updateConfig = config => dispatch => {
  dispatch({
    type: UPDATE_CONFIG,
    config
  });
};

export const updateMe = me => dispatch => {
  dispatch({
    type: UPDATE_ME,
    me
  });
};

export const updateSettings = settings => dispatch => {
  dispatch({
    type: UPDATE_SETTINGS,
    settings
  });
};

export const updatePatient = patient => dispatch => {
  dispatch({
    type: UPDATE_PATIENT,
    patient
  });
};

export const navigate = (page, loadFile, params, query, requiresAuth) => dispatch => {
  loadFile();

  dispatch({
    type: UPDATE_PAGE,
    page,
    params,
    query,
    requiresAuth
  });
};

export const authorize = (page, granted) => dispatch => {
  dispatch({
    type: AUTHORIZE_PAGE,
    page,
    granted
  });
};